/*
 * Variables
 */

$tagTextColor: hsla(43, 79%, 48%, 0.8);
$sidebarDark: #2f393f;

// Layout
$sidebarWidthDesktop: 106px;
$extraSidebarWidthDesktop: 240px;
$sidebarHeightMobile: 66px;
$appTopMarginDesktop: 167px;

/*
 * Font stuff
 */

html {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: constant(
    safe-area-inset-top
  ); /* iOS 11.0 formatting for notch */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 formatting for notch*/
  background: #faf9f7;
}

$fontFamilySans: 'Merriweather Sans', -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

$fontFamilySerif: 'Merriweather', 'Roboto', -apple-system, BlinkMacSystemFont,
  'Helvetica Neue', serif;

body,
textarea,
input,
button {
  font-family: $fontFamilySans;
  font-weight: 300;
}

/*
 * General element styling
 */

input {
  margin: 5px 0;
  padding: 7px 9px;
  font-size: 17px;
  border: 1px solid #bbb;
  border-radius: 10px;
}

textarea {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-shadow: inset 0px 1px 5px hsla(0, 0, 0, 0.05);
  background: #fff;

  font-size: 15px;
  padding: 10px;
}

textarea::placeholder,
input::placeholder {
  color: #ababab;
}

a {
  text-decoration: none;
  color: hsl(44, 75%, 50%);

  &:hover {
    text-decoration: underline;
  }
}

button:not(.icon),
input[type='submit'] {
  padding: 10px 19px;
  font-size: 15px;
  color: #5c584c;
  -webkit-appearance: none;
  background: linear-gradient(0deg, #fed73b, #fed73b), #fed631;
  border: 1px solid #eac013;
  box-shadow: 0px 4px 8px hsla(47, 98%, 23%, 0.11),
    0px 1px 2px hsla(50, 59%, 20%, 0.12),
    inset 0px 5px 6px rgba(255, 228, 122, 0.58);
  border-radius: 8px;
  font-weight: 400;

  &:disabled {
    background: lightgray;
    box-shadow: none;
    color: gray;
    border: 1px solid lightgray;
  }

  &:focus:not(:active) {
    outline: none;
    border: 1px solid darken(#eac013, 6%);
  }

  &:active {
    box-shadow: 0px 4px 8px hsla(47, 98%, 23%, 0.01),
      0px 1px 2px hsla(50, 59%, 20%, 0.02),
      inset 0px 5px 4px hsla(48, 100%, 14%, 0.05);
    outline: none;
  }
}

button.icon {
  background: #efefef;
  padding: 5px 7px 6px 8px;
  box-shadow: 0px 0px 1px #bbb;
  border: 0;
  cursor: pointer;
  border-radius: 8px;
  font-size: 13px;

  & + button.icon,
  div + & {
    margin-left: 10px;
  }

  .fa-trash-alt {
    font-size: 15px;
    padding-right: 1px;
  }

  &.linky {
    background: transparent;
    border: 1px solid #eac013;
    color: #333;

    ion-icon {
      color: #eac013;
    }

    &:active {
      background: #fbf8eb;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }
  }
}

button.cancel {
  background: lightgray;
  box-shadow: none;
  border: 1px solid lightgray;
  margin-left: 5px;
}

button.small {
  padding: 8px 12px;
  font-size: 14px;
}

button.warning {
  background: rgb(255, 0, 0);
  color: white;
  margin-left: 5px;
}

button.text {
  background: none;
  padding: 4px;
  margin: 0;
  border: 0;
  box-shadow: none;
  color: hsl(48, 84%, 50%);
  font-size: 17px;

  ion-icon {
    font-size: 24px;
    position: relative;
    top: 6px;
    left: -1px;
  }

  &:hover {
    color: hsl(48, 84%, 60%);
    cursor: pointer;
  }

  &:focus:not(:active) {
    outline: none;
    border: 1px solid darken(#eac013, 6%);
  }

  &:active {
    border: 0;
    outline: 0;
    box-shadow: none;
    color: hsl(48, 84%, 50%);
  }
}

button.unstyled {
  background: none;
  border: none;
  box-shadow: none;
  font-size: 23px;
  padding: 0 20px 20px;
  color: hsl(0, 0%, 42%);

  &:active {
    color: hsl(46, 100%, 39%) !important;
    box-shadow: none;
  }

  &:hover {
    color: hsl(46, 100%, 45%);
    cursor: pointer;
  }
}

/*
 * Utility classes
 */

.badge {
  width: 18px;
  height: 18px;
  text-align: center;
  padding: 0px 0;
  border-radius: 10px;
  background-color: gold;
  color: white;
  display: inline-block;
  font-size: 13px;
}

.dot {
  width: 11px;
  height: 11px;
  border-radius: 14px;
  background-color: gold;
  display: inline-block;
}

/*
 * Ionicons
 *
 * These seem to need a wrapper with a class, in order to be styled
 */

ion-icon {
  font-size: 19px;
}

.icon-with-label {
  &.muted {
    color: #bbb;
  }

  ion-icon {
    margin-right: 5px;
    vertical-align: -12%;
  }
}

.icon-gray {
  ion-icon {
    color: #e1e1e1;
  }
}

.icon-reaction {
  ion-icon {
    color: #eac013;
    position: relative;
    top: 4px;
  }
}

/* 
 * Reusable components
 */
.blankSlate {
  color: hsl(44, 0%, 26%);
  padding: 20px 40px;
  max-width: 800px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.4em;
}

.backButtonContainer {
  text-align: left;
}

// TODO: Remove? Don't see the purpose.
.inputComponent {
  input {
    display: block;
  }
}

/* ------ Main layout ------- */

.app {
  // Sidebar
  header.sidebar {
    background: $sidebarDark;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: $sidebarHeightMobile;
    z-index: 10;

    // On desktop, align left
    @media (min-width: 1024px) {
      height: 100%;
      width: $sidebarWidthDesktop;
      top: 0;
      left: 0;
      bottom: auto;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: center;
      display: flex;
      justify-content: space-evenly;

      @media (min-width: 1024px) {
        margin: $appTopMarginDesktop 0 0 0;
        display: block;
      }

      li {
        font-size: 0.9rem;
        display: inline-block;
        min-width: 95px;

        @media (min-width: 1024px) {
          display: block;
          margin: 15px 0;
        }

        a {
          $linkColor: hsl(204, 5, 60%);
          color: $linkColor;
          text-decoration: none;
          padding: 6px 11px 5px;
          font-size: 12px;
          font-weight: 500;
          display: block;
          transition: color 70ms ease-in;

          @media (min-width: 1024px) {
            font-size: 14px;
          }

          ion-icon {
            font-size: 36px;
            color: $linkColor;
            transition: color 70ms ease-in;
          }

          &:hover {
            color: lighten($linkColor, 30%);

            ion-icon {
              color: lighten($linkColor, 30%);
            }
          }

          &.active {
            color: white;

            ion-icon {
              color: gold;
            }
          }

          > div {
            margin-top: -2px;
            @media (min-width: 1024px) {
              margin-top: 0;
            }
          }
        }
      }
      .authed {
        // The list needs less margin
        li:first-child {
          ion-icon {
            margin-bottom: 0;
          }
        }
        // The cog needs extra margin
        li:last-child {
          ion-icon {
            margin-bottom: 4px;
          }
        }
      }
    }
    .notAuthed {
      // The list needs less margin
      li:first-child {
        ion-icon {
          margin-bottom: 2px;
        }
      }
      // The cog needs extra margin
      li:last-child {
        ion-icon {
          margin-bottom: 2px;
        }
      }
    }
  }

  .mainContent {
    &.loggedIn {
      padding-bottom: $sidebarHeightMobile;
    }

    @media (min-width: 1024px) {
      // Adjust for sidebar
      &.loggedIn {
        padding-left: $sidebarWidthDesktop;
        padding-bottom: 0;
      }

      &.withExtraSidebar {
        #Logo {
          margin-left: $extraSidebarWidthDesktop;
        }

        #Spinner {
          margin-left: $extraSidebarWidthDesktop;
        }
      }
    }

    #Logo {
      display: flex;
      justify-content: center;

      h1 {
        color: black;
        pointer-events: none;
        font-size: 0;
        font-weight: 500;
        display: block;
        background-image: url('../images/todayilearned.png');
        width: 176px;
        height: 40px;
        margin: 40px 0 79px;
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    header + section {
      @media (min-width: 1024px) {
        &.withExtraSidebarDesktop {
          margin-left: $extraSidebarWidthDesktop;
        }
      }

      > section {
        text-align: center;

        .sectionInner {
          max-width: 650px;
          padding: 18px 23px 12px;
          text-align: left;
          margin: 0 auto;

          &.centered {
            text-align: center;
          }
        }

        // Vertically adjacent sections need some spacing
        & + section {
          .sectionInner {
            padding: 20px;

            @media (min-width: 1024px) {
              padding: 30px 45px;
            }
          }
        }
      }
    }
  }
}

/*
 * Insights list
 */

.insightsList {
  text-align: center;
  position: relative;

  // Month divider
  h3 {
    margin: 11px 0 0 18px;
    font-size: 14px;
    font-weight: bold;
    color: hsl(40, 32%, 83%);
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 1024px) {
      margin-top: 20px;
    }
  }

  a {
    text-decoration: none;
  }

  div {
    div.insightContainer {
      position: relative;

      display: flex;

      .insightLink {
        flex: 1;

        div.insightInner {
          background: white;
          border: 1px solid #f4f4f4;
          box-shadow: 0px 4px 9px hsla(44, 40%, 40%, 0.05),
            0px 1px 1px hsla(44, 40%, 40%, 0.08);
          border-radius: 8px;

          margin: 10px 0px 8px 0px;
          padding: 15px 18px 14px;
          text-align: left;

          line-height: 1.45em;
          font-size: 15px;
          transition: box-shadow linear 50ms;

          &:hover {
            box-shadow: 0px 5px 12px hsla(44, 40%, 40%, 0.1),
              0px 1px 2px hsla(44, 40%, 40%, 0.19);
            color: black;
          }

          .insight {
            color: #474747;
            font-family: $fontFamilySerif;
            font-weight: 400;
            margin-bottom: 4px;
          }

          .metadata {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            font-size: 12px;
            margin-left: auto;

            @media (min-width: 1024px) {
              font-size: 13px;
            }

            // Display tags after the insight
            .tags {
              margin-right: auto;
              display: flex;
              .tag {
                display: inline-block;
                color: $tagTextColor;
                font-weight: 400;
                margin: 0 5px 0 0;

                &:hover {
                  color: lighten(saturate($tagTextColor, 25%), 8%);

                  .numberSign {
                    color: lighten(saturate($tagTextColor, 25%), 8%);
                  }
                }
              }
            }

            .date-user-and-icons {
              display: flex;
              align-self: flex-end;

              .date,
              .divider-dot,
              .user {
                color: hsl(44, 0, 67%);
                margin: 0 3px 0;
                text-decoration: none;
                display: inline-block;
                white-space: nowrap;

                &:hover {
                  color: hsl(44, 10%, 43%);
                }
              }
              > :first-child {
                margin-left: 0;
              }
              > :last-child {
                margin-right: 0;
              }

              .divider-dot {
                margin: 0 1px 0 0;
                align-self: flex-end;
              }
            }

            .date,
            .divider-dot {
              &:hover {
                color: hsl(44, 0, 67%);
              }
            }

            .visibility,
            .comments {
              margin-left: 3px;

              ion-icon {
                vertical-align: -22%;
              }
            }

            .visibility {
              ion-icon {
                vertical-align: -22%;
              }
            }
          }
        }
      }
    }
  }
}

/* ------ Individual pages ------- */

#Mine {
  .messageOnTop {
    color: #888;
    margin: 25px 0 0 0;
    text-align: center;

    > ion-icon {
      position: relative;
      top: 4px;
      font-size: 20px;
      color: #8fd537;
      margin-right: 3px;
    }

    button {
      margin-left: 5px;
      font-size: 16px;
    }

    @media (min-width: 1024px) {
      margin: 9px 0 20px 0;
      font-size: 15px;

      button {
        margin-left: 20px;
        font-size: 15px;
      }
    }
  }

  #TagFilter {
    // Disable horizontal scroll bar
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    ul {
      margin: 0 0 15px;
      padding: 10px 0;
      height: 35px;

      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: auto;

      // Disable horizontal scroll bar
      scrollbar-width: none; // FF
      -ms-overflow-style: -ms-autohiding-scrollbar; // MS

      -webkit-overflow-scrolling: touch;

      li {
        list-style-type: none;
        display: inline-block;
        padding: 0;
        margin: 5px 0;

        a {
          padding: 9px 16px;
          margin: 0 2px;
          color: hsl(44, 17%, 67%);
          cursor: pointer;
          border-radius: 25px;
          transition: all 100ms ease-in;

          &:hover {
            text-decoration: none;
            color: hsl(0, 0%, 29%);
            background: hsl(44, 37%, 95%);
          }

          &.selected {
            background: hsl(44, 37%, 92%);
            color: #333;
            font-weight: 500;

            .count {
              color: hsl(44, 80%, 57%);
            }
          }
        }

        .count {
          color: hsl(44, 35%, 72%);
          font-size: 14px;
          padding-left: 6px;
        }
      }
    }

    // On desktop, position the tags on the side
    @media (min-width: 1024px) {
      background: #fdfdfd;
      position: fixed;
      left: $sidebarWidthDesktop;
      width: $extraSidebarWidthDesktop;
      bottom: 0;
      top: 0;
      padding: $appTopMarginDesktop 10px 0 16px;
      overflow-y: scroll;
      box-sizing: border-box;

      -ms-overflow-style: none;
      scrollbar-width: none; //FF
      -ms-overflow-style: -ms-autohiding-scrollbar; // MS
      overscroll-behavior: none;

      // Disable horizontal scroll bar
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      ul {
        overflow-x: hidden;
        height: auto;
        margin-top: 5px;

        li {
          display: block;
          margin: 0 0 9px 0;

          a {
            padding: 0px 13px 0px;
            font-size: 16px;
            position: relative;
            display: block;

            &:hover {
              background: transparent;
            }

            &.selected {
              background: transparent;
              font-weight: 600;

              .numberSign {
                display: block;
              }

              .count {
                color: #333;
              }
            }

            .count {
              float: right;
              padding-top: 2px;
            }

            .numberSign {
              position: absolute;
              left: 1px;
              display: none;
              color: gold;
            }
          }
        }
      }
    }
  }
}

#TagPage {
  h1 {
    text-align: center;
    font-size: 32px;
    margin-top: 8px;
  }
}

#InsightEditor {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 40px;
    margin-top: 0;
  }

  form {
    margin: 0 auto;
    text-align: left;
    width: 100%;

    textarea {
      font-size: 16px;
      border-radius: 8px 8px 0 0;
      border-bottom: 0;
      margin-bottom: 0;
      padding: 18px 18px 12px;
      width: 100%;
      box-sizing: border-box;
      display: block;

      resize: none;
      -webkit-appearance: none;

      // Smaller font size needs a smaller insight editor
      @media only screen and (max-width: 320px) {
        font-size: 17px;
      }

      &:focus {
        outline: none;
        border-color: gold;
        border-width: 2px;
        padding: 17px 17px 12px;
      }

      &:focus + #Tags {
        border-color: gold;
        border-width: 2px;
        padding: 8px 17px 9px 17px;
      }
    }

    #Tags {
      border-radius: 0 0 8px 8px;

      border: 1px solid #eee;
      border-top: 0px;

      background: #fff;

      padding: 8px 18px 10px;
      margin-bottom: 8px;

      .tagContainer {
        position: relative;
        display: inline-block;
        margin-right: 13px;

        .hashtag {
          color: rgb(201, 201, 201);
          position: absolute;
          left: 15px;
          top: 13px;
          font-size: 15px;

          // On mobile, font size is larger, adjust accordingly
          @media only screen and (max-width: 810px) {
            top: 14px;
          }
        }

        @keyframes newTag {
          from {
            transform: scale(0.7);
            opacity: 0;
          }
        }

        input {
          padding: 8px 14px 8px 26px;
          border: 1px solid #f0f0f0;
          box-shadow: 0px 4px 8px rgba(13, 35, 47, 0.04),
            0px 1px 0px rgba(0, 0, 0, 0.08);
          border-radius: 25px;
          animation: newTag 150ms ease-in-out;
          -webkit-appearance: none;

          font-size: 14px;

          // Prevent zoom on mobile
          @media only screen and (max-width: 810px) {
            font-size: 16px;
          }

          &:focus {
            outline: none;
            border-color: hsla(51, 100, 50, 1);

            box-shadow: 0px 4px 8px hsla(48, 99, 42, 0.24),
              0px 1px 0px rgba(48, 99, 18, 0.1), 0px 0px 1px gold;
          }

          &::placeholder {
            color: rgb(201, 201, 201);
          }
        }
      }

      // Don't animate on page load
      .tagContainer:first-child input {
        animation: none;
      }
    }

    #PrivacyToggle {
      display: inline-block;
      text-align: left;
      margin-left: 20px;

      label {
        display: inline-block;
        margin-top: 3px;
        padding: 10px 20px 0 0;
        color: #797979;
        font-size: 15px;

        input {
          margin-right: 8px;

          position: relative;
          top: 1px;
        }
      }
    }

    #Submit {
      float: right;

      input {
        color: #5c584c;
        font-size: 15px;
      }
    }
  }
}

#InsightPage {
  > section:first-child > .sectionInner {
    text-align: center;
    margin-bottom: 85px;

    @media only screen and (max-width: 810px) {
      margin-bottom: 30px;
    }
  }

  #Comments {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    max-width: 550px;

    @media only screen and (max-width: 810px) {
      margin-top: 20px;
    }

    .comment {
      margin: 27px 0 27px 0;
      white-space: pre-line;
      background: white;
      border-radius: 15px;
      padding: 21px 26px;
      color: #222;

      box-shadow: 0px 5px 12px hsla(44, 80%, 20%, 0.06),
        0px 1px 2px hsla(44, 80%, 20%, 0.06);
    }

    .date {
      margin-left: 5px;
    }

    .text {
      margin: 7px 0 2px;

      h1 {
        font-size: 1.5em;
      }

      h2 {
        font-size: 1.2em;
      }

      h3 {
        font-size: 1em;
      }

      h4 {
        font-size: 0.8em;
      }

      h5 {
        font-size: 0.6em;
      }

      img {
        max-width: 92.5%;
      }

      p {
        margin: 0;

        & + p {
          margin: 1.1em 0 0;
        }
      }

      blockquote {
        background: #f9f9f9;
        border-left: 5px solid #ccc;
        margin: 1em 0;
        padding: 0.7em 10px;

        p {
          margin: 0;

          & + p {
            margin-top: 1.2em;
          }
        }
      }
    }

    h4 {
      font-size: 18px;
      color: #222;
    }

    span {
      font-size: 13px;

      &.username {
        font-weight: 600;
        font-size: 16px;

        a {
          color: hsl(44, 100%, 44%);
        }
      }

      &.date {
        color: #bbb;
        font-weight: 400;
      }

      &.insight-edit {
        font-size: 16px;
      }
    }

    textarea {
      height: 75px;
      font-size: 16px;
      width: 100%;
      padding: 20px;
      -webkit-appearance: none;
      border-radius: 15px;
      box-sizing: border-box;
    }

    #LoggedOut {
      color: #888;
      margin-top: 60px;
    }

    #AddComments {
      margin-top: 50px;
    }

    #EditComment {
      #EditTextarea {
        margin-top: 15px;
        margin-bottom: 15px;
        height: auto;
      }
    }
  }
}

#Home {
  color: #444;

  #Intro {
    line-height: 1.3em;
    padding: 10px 10px;
    border-radius: 10px;
    margin: 5px auto 0;

    @media only screen and (min-width: 1024px) {
      width: 600px;
    }

    #mascot {
      margin: 0 auto 50px;
      display: block;
      width: 150px;

      @media only screen and (min-width: 1024px) {
        width: 250px;
      }
    }

    ul {
      line-height: 1.6em;
    }

    h2 {
      margin-top: 0;
      line-height: 1.3em;
      margin-bottom: 13px;
    }

    p {
      line-height: 1.32em;
    }

    .actions {
      margin-top: 23px;

      a + a {
        margin-left: 25px;
      }
    }

    @media only screen and (min-width: 1024px) {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
}

#SignUpPage {
  text-align: center;

  div {
    margin: 0 auto;
    text-align: left;
    max-width: 310px;
  }

  form {
    input {
      min-width: 280px;
      padding: 12px;
      font-size: 16px;
      margin-bottom: 9px;
    }

    p {
      margin-top: 25px;
    }
  }
}

#ReportContent {
  margin: 100px auto 0;
  text-align: left;
  max-width: 550px;

  button {
    margin-top: 10px;
    margin-right: 10px;
  }

  #ReportContentLink {
    cursor: pointer;
    color: #bbb;

    &:hover {
      color: #353535;
    }

    ion-icon {
      position: relative;
      top: 2px;
      font-size: 14px;
    }
  }
}
